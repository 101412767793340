// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-ben-md": () => import("./../../../../src/ben.md" /* webpackChunkName: "component---src-ben-md" */),
  "component---src-ben-th-md": () => import("./../../../../src/ben-th.md" /* webpackChunkName: "component---src-ben-th-md" */),
  "component---src-charcontract-md": () => import("./../../../../src/charcontract.md" /* webpackChunkName: "component---src-charcontract-md" */),
  "component---src-charcontract-th-md": () => import("./../../../../src/charcontract-th.md" /* webpackChunkName: "component---src-charcontract-th-md" */),
  "component---src-clothes-md": () => import("./../../../../src/clothes.md" /* webpackChunkName: "component---src-clothes-md" */),
  "component---src-clothes-th-md": () => import("./../../../../src/clothes-th.md" /* webpackChunkName: "component---src-clothes-th-md" */),
  "component---src-consumable-md": () => import("./../../../../src/consumable.md" /* webpackChunkName: "component---src-consumable-md" */),
  "component---src-consumable-th-md": () => import("./../../../../src/consumable-th.md" /* webpackChunkName: "component---src-consumable-th-md" */),
  "component---src-exploring-md": () => import("./../../../../src/exploring.md" /* webpackChunkName: "component---src-exploring-md" */),
  "component---src-exploring-th-md": () => import("./../../../../src/exploring-th.md" /* webpackChunkName: "component---src-exploring-th-md" */),
  "component---src-facility-md": () => import("./../../../../src/facility.md" /* webpackChunkName: "component---src-facility-md" */),
  "component---src-facility-th-md": () => import("./../../../../src/facility-th.md" /* webpackChunkName: "component---src-facility-th-md" */),
  "component---src-fishing-md": () => import("./../../../../src/fishing.md" /* webpackChunkName: "component---src-fishing-md" */),
  "component---src-fishing-th-md": () => import("./../../../../src/fishing-th.md" /* webpackChunkName: "component---src-fishing-th-md" */),
  "component---src-flood-md": () => import("./../../../../src/flood.md" /* webpackChunkName: "component---src-flood-md" */),
  "component---src-flood-th-md": () => import("./../../../../src/flood-th.md" /* webpackChunkName: "component---src-flood-th-md" */),
  "component---src-fox-md": () => import("./../../../../src/fox.md" /* webpackChunkName: "component---src-fox-md" */),
  "component---src-fox-th-md": () => import("./../../../../src/fox-th.md" /* webpackChunkName: "component---src-fox-th-md" */),
  "component---src-guide-md": () => import("./../../../../src/guide.md" /* webpackChunkName: "component---src-guide-md" */),
  "component---src-guide-th-md": () => import("./../../../../src/guide-th.md" /* webpackChunkName: "component---src-guide-th-md" */),
  "component---src-howtostart-md": () => import("./../../../../src/howtostart.md" /* webpackChunkName: "component---src-howtostart-md" */),
  "component---src-howtostart-th-md": () => import("./../../../../src/howtostart-th.md" /* webpackChunkName: "component---src-howtostart-th-md" */),
  "component---src-intro-md": () => import("./../../../../src/intro.md" /* webpackChunkName: "component---src-intro-md" */),
  "component---src-intro-th-md": () => import("./../../../../src/intro-th.md" /* webpackChunkName: "component---src-intro-th-md" */),
  "component---src-marketplace-md": () => import("./../../../../src/marketplace.md" /* webpackChunkName: "component---src-marketplace-md" */),
  "component---src-marketplace-th-md": () => import("./../../../../src/marketplace-th.md" /* webpackChunkName: "component---src-marketplace-th-md" */),
  "component---src-materials-md": () => import("./../../../../src/materials.md" /* webpackChunkName: "component---src-materials-md" */),
  "component---src-materials-th-md": () => import("./../../../../src/materials-th.md" /* webpackChunkName: "component---src-materials-th-md" */),
  "component---src-nft-md": () => import("./../../../../src/nft.md" /* webpackChunkName: "component---src-nft-md" */),
  "component---src-nft-th-md": () => import("./../../../../src/nft-th.md" /* webpackChunkName: "component---src-nft-th-md" */),
  "component---src-offchain-md": () => import("./../../../../src/offchain.md" /* webpackChunkName: "component---src-offchain-md" */),
  "component---src-offchain-th-md": () => import("./../../../../src/offchain-th.md" /* webpackChunkName: "component---src-offchain-th-md" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-petting-md": () => import("./../../../../src/petting.md" /* webpackChunkName: "component---src-petting-md" */),
  "component---src-petting-th-md": () => import("./../../../../src/petting-th.md" /* webpackChunkName: "component---src-petting-th-md" */),
  "component---src-play-and-earn-md": () => import("./../../../../src/play-and-earn.md" /* webpackChunkName: "component---src-play-and-earn-md" */),
  "component---src-play-and-earn-th-md": () => import("./../../../../src/play-and-earn-th.md" /* webpackChunkName: "component---src-play-and-earn-th-md" */),
  "component---src-processing-md": () => import("./../../../../src/processing.md" /* webpackChunkName: "component---src-processing-md" */),
  "component---src-processing-th-md": () => import("./../../../../src/processing-th.md" /* webpackChunkName: "component---src-processing-th-md" */),
  "component---src-quest-md": () => import("./../../../../src/quest.md" /* webpackChunkName: "component---src-quest-md" */),
  "component---src-quest-th-md": () => import("./../../../../src/quest-th.md" /* webpackChunkName: "component---src-quest-th-md" */),
  "component---src-railway-md": () => import("./../../../../src/railway.md" /* webpackChunkName: "component---src-railway-md" */),
  "component---src-railway-th-md": () => import("./../../../../src/railway-th.md" /* webpackChunkName: "component---src-railway-th-md" */),
  "component---src-roadmap-md": () => import("./../../../../src/roadmap.md" /* webpackChunkName: "component---src-roadmap-md" */),
  "component---src-roadmap-th-md": () => import("./../../../../src/roadmap-th.md" /* webpackChunkName: "component---src-roadmap-th-md" */),
  "component---src-rod-md": () => import("./../../../../src/rod.md" /* webpackChunkName: "component---src-rod-md" */),
  "component---src-rod-th-md": () => import("./../../../../src/rod-th.md" /* webpackChunkName: "component---src-rod-th-md" */),
  "component---src-shrine-md": () => import("./../../../../src/shrine.md" /* webpackChunkName: "component---src-shrine-md" */),
  "component---src-shrine-th-md": () => import("./../../../../src/shrine-th.md" /* webpackChunkName: "component---src-shrine-th-md" */),
  "component---src-smartphone-md": () => import("./../../../../src/smartphone.md" /* webpackChunkName: "component---src-smartphone-md" */),
  "component---src-smartphone-th-md": () => import("./../../../../src/smartphone-th.md" /* webpackChunkName: "component---src-smartphone-th-md" */),
  "component---src-tarnsac-md": () => import("./../../../../src/tarnsac.md" /* webpackChunkName: "component---src-tarnsac-md" */),
  "component---src-tarnsac-th-md": () => import("./../../../../src/tarnsac-th.md" /* webpackChunkName: "component---src-tarnsac-th-md" */),
  "component---src-team-md": () => import("./../../../../src/team.md" /* webpackChunkName: "component---src-team-md" */),
  "component---src-team-th-md": () => import("./../../../../src/team-th.md" /* webpackChunkName: "component---src-team-th-md" */),
  "component---src-tokenomic-md": () => import("./../../../../src/tokenomic.md" /* webpackChunkName: "component---src-tokenomic-md" */),
  "component---src-tokenomic-th-md": () => import("./../../../../src/tokenomic-th.md" /* webpackChunkName: "component---src-tokenomic-th-md" */)
}

